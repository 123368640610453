
import { Component, Vue } from "vue-property-decorator";
import Search from "@/components/reusable/Search.vue";

import Pagination from "@/components/reusable/table/Pagination.vue";
import Icon from "@/components/reusable/Icon.vue";
import {
  CategoryModel,
  CategoryModelRequest,
  CategoryRequestOptions,
} from "@/models/category";
import UIkit from "uikit";
import CategoryService from "@/services/category_service";
//import Toast from "@/components/reusable/Toast.vue";
import { EventBus } from "@/events/index";
import CategoryTable from "@/components/category/CategoryTable.vue";
import { APIResponse } from "@/models/api_res";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { GlobalActions, GlobalGetters } from "@/store/modules/global/types";
import { getDelta } from "@/utility/helpers";
import { ListsGetters } from "@/store/modules/lists/types";
import MoveItem from "@/components/reusable/MoveItem.vue";
import { Delta } from "@/models/delta";
import { AuthError } from "@/services/error_service";
import CategoryMenu from "./CategoryMenu.vue";
import Auth from "@/components/Auth.vue";
import ConfirmDelete from "../reusable/modals/ConfirmDelete.vue";
@Component({
  components: {
    CategoryTable,
    Search,
    Pagination,
    Icon,
    MoveItem,
  },
})
export default class Category extends Vue {
  protected failed: string[] = [];
  protected success: string[] = [];
  protected categoryService = new CategoryService();
  protected deleteData: CategoryModel[] = [];
  protected moveData: CategoryModel[] = [];
  protected moveDestination = {} as CategoryModel;
  protected toast = false;
  protected messageHtml = "";
  protected categories: CategoryModel[] = [];
  protected categoryList: CategoryModel[] = [];
  protected disableDrag = false;
  protected pages = 1;
  protected currentPage = 1;
  protected breadcrumbs: CategoryModel[] = [];
  protected query = "";
  protected id = 0;
  protected isSubCat = false;
  protected subCatFilter: { hide: boolean; empty: boolean } = {
    hide: false,
    empty: false,
  };
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoading))
  isLoading!: boolean;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoading))
  setLoading: any;

  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetBusinessUnit))
  businessUnit!: string;

  async created() {
    this.setLoading(true);
    if (this.$route.query.q) {
      this.disableDrag = true;
    }
    this.readUrl();
    //  if (this.$route.query.deleted) {
    //this.showDeleteToast();
    //   }
  }
  protected async showDeleteToast(): Promise<void> {
    this.showToast(
      `Category ${decodeURIComponent(
        this.$route.query.deleted as string
      )} deleted successfully.`
    );
  }
  /**
   * Checks url for query parameters to decide where to send HTTP request
   *
   * If more than 1 string in path, this means we are descending down the category tree. make a request to /get categories/:path and set resulting children categories as component data to display in table, and build breadcrumbs.
   *
   * Else send to /get categories. getRequestOptions checks for any other relevant query parameters (ex: page)
   *
   */
  protected async readUrl(): Promise<void> {
    const urlPieces = this.$route.path.split("/");
    urlPieces.splice(0, 3); // remove first 3 items to get our params
    if (urlPieces.length > 0) {
      let url;
      if (urlPieces.length === 1) {
        url = "/" + urlPieces[0];
      } else {
        url = urlPieces.join("/");
      }

      const cat = await this.getCategoriesByPath(url, true);
      if (cat && cat.children) {
        this.categories = cat.children!;
        this.pages = 1;
        this.currentPage = 1;
        this.getBreadCrumbs();
        this.isSubCat = true;
      }
    } else {
      const options: CategoryRequestOptions = this.getRequestOptions();
      this.getCategories(options);
    }
  }

  mounted() {
    EventBus.$on(
      "deleteConfirmed",
      (id: number, name: string, final = false) => {
        this.deleteRequest(id, name, final);
      }
    );
    /** Global event listener for data deletion. Triggers & sends array of data selected for deletion through to confirmation modal.
     * This event is called from the <Delete> component (a child in the corresponding <Menu> component [@ex: <ProductMenu>, <MfrMenu>...]) and from the base <Table> component.
     */
    EventBus.$on("deleteRow", (data: CategoryModel[]) => {
      this.deleteData = data;
      this.$modal.show(
        ConfirmDelete,
        { deleteData: this.deleteData, type: "category" },
        { height: "auto", adaptive: true, classes: "overflow" }
      );
      //   UIkit.modal(
      //     document.getElementById("delete-modal") as HTMLElement
      //   ).show();
      // });
    });
  }

  /**
   * HTTP request for show/hide user actions, called once per selected row
   */
  public async editVisibility(ids: number[], isHidden: boolean): Promise<void> {
    this.setLoading(true);
    for (const id of ids) {
      try {
        await this.categoryService.saveCategory(
          { is_hidden: isHidden },
          id
        );
      this.categories.forEach(cat => {
            if (cat.id === id) {
              cat.is_hidden = isHidden
            }
          })
      } catch (err) {
        if (err instanceof AuthError) {
          AuthError.logout();
        } else {
          EventBus.$emit("showError", err.message);
        }
      }
    }

    if (!this.isSubCat) {
    const options = this.getRequestOptions();
    this.getCategories(options);
    } else {
      this.setLoading(false);
    }
      this.showToast(`Toggle visibility request complete.`);   
  }

  // protected sendCheckboxes(options: { hidden: string; empty: string }): void {
  //   if (!options.hidden && !options.empty) {
  //          const options: CategoryRequestOptions = this.getRequestOptions();
  //         this.getCategories(options);
  //   }
  // else if (!options.hidden)
  //    this.$router.push({ query: { ...options } });
  // }

  // protected testToasts(): void {
  //   this.success = ["Item 1", "Item 2"];
  //   this.failed = ["Item 3", "Item 4"];
  //   EventBus.$emit("showSuccess", "MESSAGE ToastSuccess", this.success);
  //   EventBus.$emit("showFail", "MESSAGE ToastFail", this.failed);
  // }
  /**
   * Build breadcrumbs
   *
   * Get URL path, split on '/', and remove first 3 itmes (static part of URL)
   * @example
   * http://www.website.com/productline/major/minor --> ['productline', 'major', 'minor']
   * @
   *
   * Then, call rebuildUrls method to build each breadcrumb url. See method for more info.
   *
   * Send url path through HTTP request. On match, API will return category. Push entire category response object to breadcrumbs array. HTML & logic in template takes care of rendering.
   */
  protected getBreadCrumbs(): void {
    const urlPieces = this.$route.path.split("/");
    urlPieces.splice(0, 3); // remove first 3 items to get our params
    const urls = this.rebuildUrls(urlPieces);

    urls.forEach(async (url, index) => {
      const cat = await this.getCategoriesByPath(url);

      // this.breadcrumbs[index] = cat;
      Vue.set(this.breadcrumbs, index, cat);
    });
  }

  /**
   * @param strings array of url pieces.
   * Rebuilds and returns array of strings into urls to match path of each category as it descends down tree
   *
   * @return urls
   *
   * @example
   * ['productline', 'major', 'minor'] --> ['/productline', '/productline/major', 'productline/major/minor']
   *
   */
  protected rebuildUrls(strings: string[]): string[] {
    if (strings.length <= 1) {
      return ["/" + strings];
    }
    const urls = ["/" + strings[0]];
    for (let i = 1; i < strings.length; i++) {
      urls.push(urls[i - 1] + "/" + strings[i]);
    }
    return urls;
  }

  beforeDestroy() {
    EventBus.$off("deleteConfirmed");
    EventBus.$off("deleteRow");
    /** UIkit modals do not leave the DOM unless explicitly destroyed. Destroying them helps with buggy functionality due to dynamic data. This method loops through all of the modal ids and remove
     * them from the DOM upon vue's beforeDestroy() lifecycle hook.
     *
     * Note that typescript does not have definitions for many UIkit methods, hence //@ts-ignore flag.
     */
    const modals = [
      "#delete-modal",
      "#move-modal",
      "#confirm-moving-modal",
      "#add-model",
      "#save-modal",
    ];
    modals.forEach((selector) => {
      const component = UIkit.modal(selector);
      if (component) {
        //@ts-ignore
        component.$destroy(true);
      }
    });
  }
  protected addNew(): void {
    if (this.id) {
      this.$router.push("/category/new?parent=" + this.id);
    } else {
      this.$router.push("/category/new");
    }
  }

  protected getId(): number | undefined {
    let id;
    if (this.$route.query.id) {
      id = parseInt(this.$route.query.id as string, 10);
    }
    return id;
  }

  protected setDestination(value: any) {
    this.moveDestination = value;
  }

  protected async sendMoveRequest(
    newParent: number,
    id: number,
    name: string,
    origin: CategoryModel | null = null,
    final = false
  ): Promise<void> {
    this.setLoading(true);
    try {
      await this.categoryService.moveCategory(newParent, id);
      this.success.push(name);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        this.failed.push(name);
        // EventBus.$emit("showError", err.message);
      }
    }
    if (final) {
      const options: CategoryRequestOptions = this.getRequestOptions();
      this.getCategories(options);
      if (this.success.length > 0) {
        EventBus.$emit(
          "showSuccess",
          `${
            this.success.length > 1 ? "Categories have" : "Category has"
          } been moved successfully.`,
          []
        );
      }
      if (this.failed.length > 0) {
        EventBus.$emit(
          "showFail",
          `The move request for the following ${
            this.failed.length > 1 ? "categories" : "category"
          } failed. Please try again.`,
          this.failed
        );
      }
      this.success = [];
      this.failed = [];
      this.moveData = [];
    }
  }

  /**
   * Navigation logic for ascending category tree
   *
   * If there is only 1 item in breadcrumbs, fetch unfiltered top level categories
   *
   * If there are more than 1 breadcrumbs, route to the url in the breadcrumb at [currentindex-1] (or [len-2])
   * Then, logic in methods called in created() & readUrl() takes care of what to render (triggers component rerender)
   */
  protected goBack() {
    const len = this.breadcrumbs.length;
    if (len === 1) {
      this.$router.push("/category");
    } else {
      const item = this.breadcrumbs[len - 2];
      this.$router.push("/category/list" + item.url);
    }
  }

  protected move(data: CategoryModel[]) {
    this.moveData = data;
    UIkit.modal(document.getElementById("move-modal") as HTMLElement).show();
  }

  /**
   * Categories API request
   * @param optionsObject default is {roots: true} which returns the top level of the categories (product lines)
   *
   * see CategoryRequestOptions model for other request options
   */
  protected async getCategories(
    optionsObject: CategoryRequestOptions = { roots: true }
  ): Promise<void> {
    try {
      const payload: CategoryRequestOptions = {
        ...optionsObject,
        bu: this.businessUnit
      }
      const res: APIResponse = await this.categoryService.getCategories(
        payload
      );
      this.id = 0;
      this.categories = res.results;
      this.pages = res.meta.total_pages;
      this.currentPage = res.meta.page;
      this.setLoading(false);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected filterSubCategories(filter: string, status: boolean): void {
    if (filter === "hide") {
      this.subCatFilter.hide = status;
    } else if (filter === "empty") {
      this.subCatFilter.empty = status;
    }
  }

  protected get filteredCategories(): CategoryModel[] {
    let filteredArray = this.categories;
    if (this.subCatFilter.hide && this.subCatFilter.empty) {
      filteredArray = filteredArray.filter(
        (cat) => cat.is_hidden || (cat.product_count === 0 && cat.is_leaf)
      );
    } else if (this.subCatFilter.hide) {
      filteredArray = filteredArray.filter((cat) => cat.is_hidden);
    } else if (this.subCatFilter.empty) {
      filteredArray = filteredArray.filter(
        (cat) => cat.product_count === 0 && cat.is_leaf
      );
    }
    return filteredArray;
  }

  protected async getCategoriesByPath(
    path: string,
    full = false
  ): Promise<CategoryModel> {
    let category;
    try {
      const res: APIResponse = await this.categoryService.getCategories({
        path: path,
        full: full,
        bu: this.businessUnit,
      });

      category = res.results[0];
      if (category) {
        this.id = category.id;
      }
      this.setLoading(false);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
    return category;
  }

  /**
   * Paginate method triggered by child <Pagination> component.
   * First, calls this.getRequestOptions() to retain any other options (like query term)
   * NOTE: page property in returned options object must be overwritten -- this.getRequestOptions will return the current page.
   *
   * Second, send options object into API get request to get appropriate page of data.
   *
   * Finally, add page query to url if it is not already there. This ensure the correct page query is retained and will fetch correct data if user refreshes.
   */
  protected paginate(page: number) {
    const options: CategoryRequestOptions = this.getRequestOptions();
    options.page = page;
    this.getCategories(options);
    if (!this.$route.query.page || this.$route.query.page !== page.toString()) {
      this.$router.push({
        query: { ...this.$route.query, page: page.toString() },
      });
    }
  }

  /**
   * Checks URL for current query terms. This should be called on every get request unless user is requesting unfiltered data
   * The object returned varies based on the endpoint being accessed. See model for each request for more details.
   */
  protected getRequestOptions(): CategoryRequestOptions {
    const optionsObject = {} as CategoryRequestOptions;
    if (this.query || this.$route.query.q) {
      this.query = this.$route.query.q
        ? (this.$route.query.q as string)
        : this.query;
      optionsObject.q = this.query;
    }
    if (this.$route.query.page) {
      optionsObject.page = parseInt(this.$route.query.page as string, 10);
    }
    if (this.$route.query.hidden) {
      optionsObject.hidden = true;
    }
    if (this.$route.query.empty) {
      optionsObject.empty = true;
    }
    if (!this.$route.params || !this.$route.query.id) {
      optionsObject.roots = true;
    }
    if (this.$route.query.q) {
      delete optionsObject.roots;
      optionsObject.full = true;
    }
    return optionsObject;
  }

  protected async getSingleCategory(id: number): Promise<CategoryModel> {
    let category = {} as CategoryModel;
    try {
      const res: CategoryModel = await this.categoryService.getSingleCategory(
        id
      );
      category = res;
      this.setLoading(false);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
    return category;
  }

  public receiveSearchTerm(query: string): void {
    if (this.query !== query) {
      this.query = query;
      this.$router.push({ path: "/category", query: { q: query } });
    }
  }

  /** Reset search by re-requesting unfiltered data */
  public reset(): void {
    this.query = "";
    const options = { ...this.$route.query };
    delete options.q;
    this.$router.push({ query: { ...options } });
  }
  /**
   * @param id id of item to be deleted
   * @param name name of item to be deleted, used in <Toast> confirmation
   * @param final optional, default: false, flags the final item in the request array; triggers <Toast> confirmation, refreshes data
   *
   * in the <{Path}Editor> component, @param final is not used.
   */
  protected async deleteRequest(
    id: number,
    name: string,
    final = false
  ): Promise<void> {
    this.setLoading(true);
    try {
      await this.categoryService.deleteCategory(id);
      this.success.push(name);
      if (this.isSubCat) {
      this.categories = this.categories.filter(cat => cat.id !== id);
    }} catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        this.failed.push(name);
        // EventBus.$emit("showError", err.message);
      }
    }
    if (final) {
      if (!this.isSubCat) {
      const optionsObject = this.getRequestOptions();
      this.getCategories(optionsObject);
      } else {
        this.setLoading(false)
      }
      if (this.success.length > 0) {
        EventBus.$emit(
          "showSuccess",
          `${
            this.success.length > 1 ? "Categories have" : "Category has"
          } been deleted successfully.`,
          []
        );
      }
      if (this.failed.length > 0) {
        EventBus.$emit(
          "showFail",
          `The delete request for the following ${
            this.failed.length > 1 ? "categories" : "category"
          } failed. Please try again.`,
          this.failed
        );
      }
      this.success = [];
      this.failed = [];
      this.moveData = [];
    }
  }

  protected closeToast(): void {
    this.toast = false;
    this.failed = [];
    this.success = [];
  }
  protected showToast(msg: string): void {
    this.messageHtml = msg;
    this.toast = true;
  }
  /**
   * API request to reorder category
   */
  protected async reorderRequest(body: Delta): Promise<void> {
    try {
      await this.categoryService.saveCategory(body, body.id as number);
      EventBus.$emit("showSuccess", `Category order updated.`, []);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  /**
   * <draggable> component event emitted from child table component.
   *
   * The event includes
   * @param stringId the id as a string, which is the id of the element clicked (corresponds with database id)
   * @param oldIndex starting index
   * @param newIndex ending index
   *
   * The old & new index value are used to calculate delta (this change in position between old & new index) which is the value expected by the API reorder request
   */
  protected reorder(
    stringId: string,
    oldIndex: number,
    newIndex: number
  ): void {
    const id = parseInt(stringId, 10);
    const delta = getDelta(oldIndex, newIndex);
    const body: Delta = { delta, id };
    this.reorderRequest(body);
  }
}
