import {
  APIError,
  AssetConflictError,
  AuthError,
  ConflictError,
  EntityError,
  NotFoundError
} from "@/services/error_service";
import {
  AssetModel,
  AssetModelRequest,
  AssetRequestOptions
} from "@/models/asset";

import { APIResponse } from "@/models/api_res";
import Vue from "vue";
import axios from "axios";

/**
 * Creates a new Asset Service

 * @example
 * const assetService = new AssetService();
 * assetService.getasset();
 */

export default class AssetService {
  /**
   * Get paginated list of asset
   * Filtered by parameters
   * @param {object} optionsObject optional:
   *  {
   *    @param {number} page page of results to return, optional
   *    @param {number} offset number of results to return, optional (default = 10 in the api, setting to 50 here)
   *    @param {string} q Query term for keyword search
   *    @param {array} hide Filter out files of the given type. Can be passed multiple times. Available values : dir, img, doc
   *    @param {string} path Retrieve files at a given path. If passed but with a value, then all files will be retrieved. If omitted, files in the root will be returned.
   *
   * @returns {APIResponse} - response object
   */
  public async getAssets(
    optionsObject?: AssetRequestOptions
  ): Promise<APIResponse> {
    let assets;
    let url = process.env.VUE_APP_API_URL + "/assets";
    if (optionsObject) {
      let counter = 0;
      let char = "?";
      if (optionsObject.page) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "page=" + optionsObject.page;
      }
      if (optionsObject.q) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "q=" + optionsObject.q;
      }
      if (optionsObject.offset) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "per_page=" + optionsObject.offset;
      }
      if (optionsObject.path) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "path=" + optionsObject.path;
      } else if (!optionsObject.path && optionsObject.q) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "path=";
      } else if (!optionsObject.path) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "path=/assets";
      }
      if (optionsObject.hide) {
        if (typeof optionsObject.hide === "string") {
          counter++;
          if (counter > 1) {
            char = "&";
          }
          url += char + "hide=" + optionsObject.hide;
        } else {
          optionsObject.hide.forEach(val => {
            counter++;
            if (counter > 1) {
              char = "&";
            }
            url += char + "hide=" + val;
          });
        }
      }

      if (optionsObject.bu) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "bu=" + optionsObject.bu;
      }
    }
    try {
      const options = {
        withCredentials: true
      };
      const res = await axios.get(url, options);
      assets = res.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
    return assets;
  }

  /**
   * POST new asset to server
   * @param {Asset} request - new asset data object
   * @returns {Asset} - newly created asset object
   */
  public async createNewAsset(request: AssetModelRequest): Promise<AssetModel> {
    let asset;

    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      const res = await axios.post(
        process.env.VUE_APP_API_URL + "/assets",
        body,
        options
      );
      asset = res.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Asset already exists.");
      } else if (err.response && err.response.status === 422) {
        throw new EntityError(
          "Error: Unprocessable entry. Please check files and try again."
        );
      } else if (err.response && err.response.status === 413) {
        throw new APIError(
          "Error: File too large. Please reduce size to 5kb or less."
        );
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
    return asset;
  }

  /**
   * Get selected single asset
   * @param {number} id - asset id
   * @returns {Asset} - selected asset's data object
   */
  public async getSingleAsset(id: number): Promise<AssetModel> {
    let asset: AssetModel;

    const options = {
      withCredentials: true
    };
    try {
      const res = await axios.get(
        process.env.VUE_APP_API_URL_NODE + "/assets/" + id
      );
      asset = res.data.data;
    } catch (err) {
      throw new APIError(
        "Something went wrong. Please try again.",
        err.status
      );
    }
    return asset;
  }

  /**
   * Save current asset
   * @param {number} id - asset id
   * @param {Asset} request - asset request data
   * @returns {Promise<void>}
   */
  public async saveAsset(request: AssetModel, id: number): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      await axios.patch(
        process.env.VUE_APP_API_URL_NODE + "/assets/" + id,
        body,
        options
      );
    } catch (err) {
      throw new APIError("Something went wrong. Please try again.");
    }
  }

  /**
   * Delete asset
   * @param {number} id - asset id
   * @returns {Promise<void>}
   */
  public async deleteAsset(id: number): Promise<void> {
    try {
      const options = {
        withCredentials: true
      };
      return await axios.delete(process.env.VUE_APP_API_URL + "/assets/" + id);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested asset not found.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }
}
