
import { Component, Prop } from "vue-property-decorator";
import RowMenu from "@/components/reusable/menu/RowMenu";
import UIkit from "uikit";
/**
 * All menu components use the prop "table" to decide what children components to render.
 *
 * Table === true means the menu is present in the parent table
 * Table === false means the menu is present on the edit screen (usually means hiding certain components, like <Edit>)
 */

@Component
export default class CategoryMenu extends RowMenu {
  @Prop() id!: number;
  @Prop() is_hidden!: boolean;
  @Prop() is_leaf!: boolean;
  @Prop() url!: string;
  @Prop() display_name!: string;
  @Prop() product_count!: number;
  @Prop({ default: true }) table!: boolean;
  protected toggleHide(): void {
    this.$emit("toggleHide", this.id, !this.is_hidden);
  }

  get query(): string {
    return "?category=" + this.id;
  }
}
