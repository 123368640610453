
import { Component, Vue, Prop } from "vue-property-decorator";
import Autocomplete from "@/components/reusable/Autocomplete.vue";
import { CategoryModel, CategoryRequestOptions } from "@/models/category";
import CategoryService from "@/services/category_service";
import AssetService from "@/services/asset_service";
import { APIResponse } from "@/models/api_res";
import { AuthError } from "@/services/error_service";
import { EventBus } from "@/events/index";
import { AssetModel, AssetRequestOptions } from "@/models/asset";
@Component({
  components: { Autocomplete }
})
export default class MoveItem extends Vue {
  @Prop({ default: false }) product!: boolean;
  @Prop({ default: false }) asset!: boolean;
  protected dataList: CategoryModel[] = [];
  protected categoryService = new CategoryService();
  protected assetService = new AssetService();
  protected updateField(value: any): void {
    this.$emit("setDestination", value);
  }

  protected autoCompleteFetch(value: any): void {
    if (this.product) {
      this.getCategories({ q: value, leaf: true });
    } else if (this.asset) {
      this.getAssets({ q: value, path: "" });
    } else {
      this.getCategories({ q: value });
    }
  }

  protected async getCategories(
    optionsObject?: CategoryRequestOptions
  ): Promise<void> {
    try {
      const res: APIResponse = await this.categoryService.getCategories(
        optionsObject
      );
      this.dataList = res.results;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected async getAssets(
    optionsObject?: AssetRequestOptions
  ): Promise<void> {
    try {
      const res: APIResponse = await this.assetService.getAssets(optionsObject);
      this.dataList = res.results.filter((item: AssetModel) => {
        return item.file_type === "dir";
      });
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }
}
